<template>
  <div>
    <v-card elevation="3" rounded outlined :style="[allSubtasksClosed ? {'background-color': '#8A8A8A'} : '']" >
      <v-card-text class="font-weight-regular text--primary">
        <v-container v-if="task.new">
          <v-row align="center">
            <v-col cols="auto" class="text-body-1 font-weight-medium">Task Title:</v-col>
            <v-col>
              <v-text-field v-model="titleValue" outlined hide-details dense/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Mentionable @onMention="handleNewTaskMentions" :items="usersToMention" :input.sync="descValue"
                           label="Describe the task needed"/>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-text-field v-if="isEditing" outlined v-model="task.title">
            <template v-slot:append>
              <v-icon  @click="patchTask" style="color: #4CAF50;font-size: 30px;cursor:pointer; ">mdi-send-circle</v-icon>
            </template>
          </v-text-field>
          <p  v-else  class="text-body-1 font-weight-medium">{{ task.title.toUpperCase() }}
            <v-icon v-if="allSubtasksClosed" style="color: #4CAF50;font-size: 30px">mdi-check-circle</v-icon>
            <v-icon style="cursor:pointer;" @click="isEditing=true" v-else-if="currentUser.user_id == task.author_id" >mdi-pencil</v-icon>
          </p>
          <v-divider/>
          <TranscriptQueueTaskNote :taskID="task.id" :authorID="task.author_id" :body="task.description" :subtaskClosed="allSubtasksClosed" :author="task.author_name" :created_on="task.created"
                                   class="mt-4"/>
          <div v-if="fileUploadLoading" class="pt-10">
            <h2 class="text-center">Files are uploading please wait...</h2>
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container class="d-flex justify-end">
          <v-menu v-if="!task.new && (roleId === 1 || roleId === 13)" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-show="usersRole == 1 || usersRole == 13"
                  color="primary"
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  class="px-3"
              >
                <v-icon left>mdi-plus</v-icon>
                Create Subtask
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="createSubtask(2)">
                <v-list-item-title>File Request</v-list-item-title>
              </v-list-item>
              <v-list-item @click="createSubtask(1)">
                <v-list-item-title>Request Explanation</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
              v-if="task.new"
              :disabled="!descValue || !titleValue"
              style="width: 160px"
              color="primary"
              @click="saveTask"
          >Request task
          </v-btn
          >
        </v-container>
      </v-card-actions>
    </v-card>
    <div v-for="(subtask, i) in subtasks" :key="subtask.id">
      <v-card v-if="subtask.new" elevation="3" rounded outlined class="mt-4">
        <v-card-text class="text--primary">
          <v-row align="center">
            <v-col cols="auto">
              <span class="text-body-1">Assign to: </span>
            </v-col>
            <v-col cols="auto" md="5">
              <v-select
                  outlined
                  dense
                  hide-details
                  label="Select Assignee"
                  :items="usersToAssign"
                  v-model="selectedAssignee"
                  item-text="name"
                  item-value="id"
              />
            </v-col>
          </v-row>
          <v-row v-if="subtask.type === 2" align="center">
            <v-col cols="auto">File request:</v-col>
            <v-col cols="5">
              <v-select
                  v-model="selectedFile"
                  label="Select File"
                  outlined
                  hide-details
                  dense
                  :items="files"
                  item-text="document_name"
                  item-value="document"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <Mentionable @onMention="handleMentions" :input.sync="subtaskDesc" :items="usersToMention"
                           label="Describe the subtask"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="cancelSubtask(i)">Cancel</v-btn>
          <v-btn v-if="subtask.type === 2" :disabled="!subtaskDesc" color="primary"
                 @click="submitSubtask(subtask)">submit subtask
          </v-btn>
          <v-btn v-else :disabled="!subtaskDesc" color="primary" @click="submitSubtask(subtask)">submit subtask</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else elevation="3" rounded outlined class="mt-4"
              :style="[subtask.closed ? {'background-color': '#8a8a8a'} : '']">
        <v-card-title class="pb-0 d-flex align-center justify-space-between">
          <p>Subtask {{ i + 1 }}<span v-if="subtask.closed">
            <v-icon v-if="subtask.satisfied" style="color: #4CAF50;font-size: 30px">mdi-check-circle</v-icon>
            <v-icon v-else style="color: #dc0000;font-size: 30px">mdi-alpha-x-circle</v-icon>
          </span> </p>
          <v-btn v-show="usersRole===1 && subtask.assignee_id !== currentUser.current_user_id && !subtask.closed" @click="claimSubtask(subtask)" color="primary">Claim</v-btn>

        </v-card-title>

        <span class="text-body-1 ml-4">Assignee: </span> <span v-if="subtask.closed">{{ subtask.assignee_name }}</span>

        <v-menu
            v-if="!subtask.closed"
            v-model="assigneeMenu[subtask.id]"
            :close-on-content-click="false"
            offset-y
            max-height="300"
        >
          <template v-slot:activator="{ on, attrs }">
          <span
              class="blue--text text-decoration-underline ml-4"
              v-bind="attrs"
              v-on="on"
              @click="getUsersToMention()"
          >
            {{ subtask.assignee_name }}
          </span>
          </template>

          <v-card class="mx-auto" width="300" max-width="300">
            <v-list dense>
              <p class="text-center font-weight-bold text-h6">Assign to</p>
              <v-list-item-group
                  v-model="selectedAssignee"
                  color="primary"
              >
                <v-list-item
                    v-for="(item, i) in selectedAssignee"
                    :key="i"
                    @click="changeTheAssignee(subtask, item)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.name"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-menu>
        <v-card-text class="text--primary mt-5">
          <TranscriptQueueTaskNote :subtask-closed="subtask.closed" :body="subtask.note" :author="subtask.author_name"
                                   :created_on="subtask.created"/>
          <div class="mt-3 mb-3">
            <v-card elevation="4" v-for="doc in subtask.documents" class="pa-2 mt-1 d-flex justify-space-between"
                    style="border-radius: 10px;border: 1px solid gainsboro" v-if="doc.upload_id">
              <div class="d-flex flex-column">
                <span>File: {{ doc.original_file_name }}</span>
                <span>Type: {{ doc.document_type ? doc.document_type : 'Filedrop'}}</span>
              </div>
              <v-btn @click="downloadFile(doc)" color="primary">Download</v-btn>
            </v-card>
          </div>
          <v-divider/>
          <div v-for="x in subtask.notes" class="ml-12">
            <TranscriptQueueTaskNote class="mt-3" :body="x.body" :subtask-closed="subtask.closed"
                                     :author="x.author_name" :created_on="x.created"/>
          </div>
          <Mentionable v-if="!subtask.closed" @onMention="handleMentions" class="mt-4"
                       :input.sync="subtask.newNote" :items="usersToMention" label="Add note to the subtask"/>
          <div v-for="(doc,j) in subtask.documents" style="padding-top: 20px"
               v-if="!fileUploadLoading &&
               (usersRole === 7 || usersRole === 4) &&
               subtask.type_id === 2 &&
               !doc.upload_id">
            <h2 class="text-center">Upload File Type: {{doc.document_type ? doc.document_type : 'Filedrop'}}</h2>
            <div style="background: #f8f8ff; width: 80%; margin: auto; padding: 33px;">
              <div
                  id="drop-area"
                  @dragover.prevent
                  @drop="handleDrop($event,doc)"
                  @dragenter="dragEnter"
                  @dragleave="dragLeave"
                  class="drop-area"
              >
                <input
                    type="file"
                    :name="`fields[assetsFieldHandleUploads${i}${j}][]`"
                    :id="'assetsFieldHandleUploads' + i + j"
                    class="d-none absolute"
                    @input="uploadFiles($event,doc)"
                    ref="fileUpload"
                    accept="application/*"
                    multiple

                />

                <label :for="`assetsFieldHandleUploads${i}${j}`" class="block cursor-pointer">
                <span style="cursor: pointer" class="d-flex flex-column align-center">
                  <v-icon style="font-size: 5rem">mdi-upload</v-icon>
                  <b class="text-h6">Drag and drop files or Browse</b>
                </span>
                </label>
              </div>
            </div>
            <div class="d-flex justify-center mt-3">
              <v-btn @click="openFileDialog" class="primary">Upload Files</v-btn>
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="!subtask.closed" class="d-flex justify-space-between pa-4">
          <v-btn @click="openDialog(subtask)" v-if="usersRole === 1 || usersRole === 13" :disabled="!subtask.id"
                 :loading="loadingNote" color="primary">close task
          </v-btn>
          <v-btn @click="submitSubtaskNote(subtask)" :disabled="!subtask.newNote" :loading="loadingNote"
                 color="primary">submit note
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-dialog v-model="sattisfiedDialog" width="500px">
      <v-card class="flex justify-center align-center text-center">
        <v-card-title class=" text-center mb-12">Was the subtask resolved successfully?</v-card-title>
        <v-card-actions class="flex justify-space-between">
          <v-btn color="primary" @click="closeSubtask(1)">Yes</v-btn>
          <v-btn color="error" @click="closeSubtask(0)">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import TranscriptQueueTaskNote from "../TranscriptQueue/TranscriptQueueTaskNote.vue";
import TasksService from "@/services/tasks.service";
import ClientsService from "../../services/clients.service";
import Mentionable from "./Menitionable.vue";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";
import ClientService from "@/services/clients.service";

export default {
  name: "AnnouncementDetails",
  components: {Mentionable, TranscriptQueueTaskNote},
  props: {
    task: {
      type: Object,
      required: true,
    },
    usersToMention: {
      type: Array,
    },
    usersToAssign: {
      type: Array,
    },
    files: {
      type: Array,
    },
    roleId: {
      type: Number,
    },
    index: {
      type: Number,
    }
  },
  data: () => ({
    mentioned: [],
    isEditing: false,
    newTaskMentioned: [],
    satisfiedSubtask: false,
    fileUploadLoading: false,
    sattisfiedDialog: false,
    loadingNote: false,
    descValue: "",
    subtaskDesc: "",
    titleValue: "",
    newNote: "",
    selectedFile: null,
    subtasks: [],
    assigneeMenu: {},
    selectedAssignee: undefined,
    users: [],
    usersRole: null,
  }),
  methods: {
    getUsersToMention() {
      ClientService.getUsersToMention(this.$route.params.clientId).then(res => {
        this.selectedAssignee = res
      })
    },
    changeTheAssignee(subtask, item){
      TasksService.patchClientSubtask(subtask.id, {assignee: item.id})
          .then(() => {
            this.$emit("subtaskCreated");

          })
    },
    claimSubtask(subtask) {
      this.loading = true
      TasksService.patchClientSubtask(subtask.id, {assignee: this.currentUser.current_user_id})
          .then(() => {
            this.$func.popMessage('Subtask claimed successfully!', 'success')
            this.$emit("subtaskCreated");

          })
          .finally(() => this.loading = false)
    },
    handleMentions(e) {
      this.mentioned = Object.keys(e).map(key => parseInt(key))
    },
    handleNewTaskMentions(e) {
      this.newTaskMentioned = Object.keys(e).map(key => parseInt(key))
    },
    handleDrop(event,document, subtask_id) {

      event.preventDefault();
      const files = event.dataTransfer.files;
      this.handleFiles(files,document, subtask_id);
    },
    dragEnter(event) {
      event.preventDefault();
      event.target.classList.add('drag-over');
    },
    dragLeave(event) {
      event.preventDefault();
      event.target.classList.remove('drag-over');
    },
    openDialog(sub) {
      this.selectedSubtask = sub
      this.sattisfiedDialog = true
    },
    closeSubtask(satisfied) {
      this.loadingNote = true
      TasksService.patchClientSubtask(this.selectedSubtask.id, {closed: 1, satisfied: satisfied,assignee:this.selectedSubtask.author_id}).finally(() => {
        this.$func.popMessage('Subtask closed successfully!', 'success')
        this.loadingNote = false
        this.$emit("subtaskClosed");
      });
    },
    handleFiles(files, document) {
      const uploadPromises = [];
      this.fileUploadLoading = true;
      const clientId = this.$route.params.clientId

      // Iterate through each selected file
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        const file = files[i];

        // Append the file to the FormData object
        formData.append('uploaded_file', file);

        // Append additional parameters
        formData.append('upload_type', 'filedrop');
        formData.append('sub_task_document', document.id);

        const uploadPromise = ClientsService.uploadClientCSVFileWithReturningData(clientId, formData)
            .then((data) => {
              // Handle response if needed
              this.$func.popMessage('Successfully uploaded!', 'success')
              this.$emit("subtaskCreated");
              //console.log('File uploaded:', data);
            })
            .catch((error) => {
              // Handle error if needed
              //console.error('Error uploading file:', error);
            });

        uploadPromises.push(uploadPromise);
      }

      // If you need to do something after all files are uploaded
      Promise.all(uploadPromises)
          .then(() => {
            this.fileUploadLoading = false;
            //console.log('All files uploaded successfully');
          })
          .catch((error) => {
            this.$func.popMessage(error, "error")
            this.fileUploadLoading = false;
            //console.error('Error uploading files:', error);
          });
    },
    uploadFiles(event, document) {
      const files = event.target.files;
      this.handleFiles(files, document);
    },
    openFileDialog() {
      // Trigger the file input's click event
      this.$refs.fileUpload[0].click();
    },
    saveTask() {
      const clientId = this.$route.params.clientId

      const taskModel = {
        title: this.titleValue,
        description: this.descValue,
        mentioned: this.newTaskMentioned
      };

      TasksService.postClientTask(clientId, taskModel).then((res) => {
        this.$func.popMessage('Task created successfully!', 'success')
        this.newTaskMentioned = []
        this.titleValue = ""
        this.descValue = ""
        this.$emit("taskCreated", res, this.index);
      }).finally(() => {
        this.loading = false;
      });
    },
    createSubtask(type) {
      //this.getDocumentTypes();
      this.subtasks.unshift({
        task_id: this.task.id,
        type,
        new: true,
      })
    },
    submitSubtask(task) {
      const subtaskModel = {
        ...task,
        body: this.subtaskDesc,
        assignee: this.selectedAssignee,
        mentioned: this.mentioned
      };

      if (task.type === 2)
        subtaskModel['expected_file'] = this.selectedFile

      TasksService.postClientSubtask(subtaskModel).then((res) => {
        this.$func.popMessage('Subtask created successfully!', 'success')
        this.$emit("subtaskCreated");
        this.subtaskDesc = ""
        this.selectedFile = null
        this.mentioned = []
        this.selectedAssignee = undefined
      }).finally(() => {
        this.loading = false;
      });
    },
    submitSubtaskNote(subtask) {
      this.loadingNote = true
      const payload = {
        body: subtask.newNote,
        mentioned: this.mentioned
      }
      TasksService.postSubtaskNote(subtask.id, payload).then(res => {
        this.$func.popMessage('Note added successfully!', 'success')
        this.$emit("subtaskCreated");
        subtask.newNote = ""
        this.mentioned = []
      }).finally(() => {
        this.loadingNote = false;
      });
    },
    cancelSubtask(index) {
      this.subtasks.splice(index, 1)
      this.subtaskDesc = ""
      this.selectedAssignee = undefined
      this.selectedFile = null
    },
    reloadTask(task) {
      this.descValue = "";
      this.titleValue = "";
      this.selectedFile = null
      this.subtasks = task.sub_tasks ?? []
    },
    async getUser() {
      await AuthService.getCurrentUser(this.currentUser.current_user_id).then(
          (res) => {
            this.usersRole = res.data.role_id;
          }
      );
    },
    async downloadFile(file) {
      try {
        const response = await fetch(file.url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl
        link.download = file.original_file_name;

        // Simulate click
        link.click();

        // Clean up
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
    patchTask(){
      TasksService.patchClientTask(this.task.id, {title: this.task.title},'title').then(() => {
        this.isEditing = false
        this.reloadTask(this.task);
        this.$emit('updateTasks')
      })
    }
  },
  computed: {
    allSubtasksClosed() {
      if (!this.task.sub_tasks || !this.task.sub_tasks.length) return  false;
      return this.task.sub_tasks.every((subtask) => subtask.closed);
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  watch: {
    task(newVal) {
      this.reloadTask(newVal);
    },
  },
  created() {
    this.reloadTask(this.task);
    this.getUser();
    ClientsService.getUsers().then(res => {
      this.users = res.users
    })
  },
};
</script>